html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  line-height: 1.6;
  font-size: 18px;
  background: #f5f5f5;

  @media (max-width: 768px) {
    font-size: 14px;
  }
}

pre {
  background-color: #2d2d2d;
  color: #f8f8f2;
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
}

code {
  background-color: #f8f8f2;
  color: #2d2d2d;
  padding: 2px 4px;
  border-radius: 3px;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cm-main--blog h2,
.cm-main--blog h3,
.cm-main--blog h4,
.cm-main--blog h5 {
  margin-bottom: 0;
}

.cm-main--blog img {
  max-width: 100%;
  border: 1px solid #f8f8f8;
}

.dark-theme .cm-main--blog img {
  border: 0;
  background: #fff;
}

.cm-main--blog p {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.cm-main--blog {
  overflow-wrap: break-word;
}

.cm-main--blog ol,
.cm-main--blog ul {
  margin: 0.2em;
}

.pages ul {
  margin-top: 0;
  margin-bottom: 1em;
}

@media (max-width: 768px) {
  svg#logo {
    width: 180px;
  }
}