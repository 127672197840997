/* Loader.css */

/* Fullscreen overlay */
.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Transparent black overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above other elements */
  }
  
  /* Loader wrapper */
  .loader img {
    width: 50px; /* Adjust size as needed */
    height: 50px;
    animation: fade-in 0.5s ease-in-out infinite; /* Optional: Add animation */
  }
  
  /* Optional animation */
  @keyframes fade-in {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
  